.topbar {
  width: 100%;
  height: 50px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 1px 6px rgba(0, 0, 0, 0.23);
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topRight {
  display: flex;
  align-items: center;
}

.topLeft {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  margin-right: 1rem;
}

.tollbar {
  padding-left: 4rem;;
}

.tollbarItens {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
}

.tollbarItens:hover {
  border-radius: 10px;
  background-color: #eee;
}

.tollbarItensActive {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  border-radius: 10px;
  background-color: #0097a722;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

/* OLD CODE */

.avatar {
  grid-area: avatar;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.avatar__name {
  flex: 1;
  text-align: right;
  margin-right: 1em;
}

.menu {
  grid-area: menu;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.menu__text {
  display: none;
}

/* @media screen and (min-width: 850px) { */
.menu {
  flex-direction: column;
  align-items: normal;
  justify-content: flex-start;
}
.menu__text {
  display: inline-block;
}
.avatar {
  flex-direction: column;
}
.avatar__name {
  margin: 1em 0;
  font-size: 0.8rem;
}
/* } */

/* userButton */

.menuContainer {
  position: relative;
}

.userButton {
  background: #eee;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.1s;
  cursor: pointer;
}

.userButton::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url("../../assets/person.svg") no-repeat center center;
}

.userButton:focus,
.userButton:hover,
.userButtonActive {
  outline: none;
  background: #eee;
  border: 5px darkgray;
}

.navUserButton {
  border-radius: 0.2rem;
  position: absolute;
  transition: 0.3s;
  transform: initial;
  display: block;
  top: 100%;
  right: -10px;
  width: 8rem;
  background: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
  font-size: 0.9rem;
}

.navUserButton ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navUserButton li {
  border-bottom: 1px solid #dddddd;
}

.navUserButton li button {
  font-size: 0.9rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  border-radius: 0.25rem;
  text-align: left;
  cursor: pointer;
  background: white;
  border: none;
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.navUserButton li button:hover {
  background: lightgray;
  border-radius: 0.2rem;
}

.navUserButton svg {
  margin-right: 0.5rem;
}

/* TRASH */

.calendarButton {
  cursor: pointer;
  background: transparent;
  padding: 0.5rem;
  border-radius: 12px;
  width: 100%;
  text-align: left;
  border: none;
}

.calendarButton:hover {
  outline: none;
  background: #eee;
}

.calendarButton h3 {
  font-weight: bold;
  font-size: medium;
  color: #6b7280;
}

.buttonActive {
  cursor: pointer;
  background: #dbeafe;
  padding: 0.5rem;
  border-radius: 12px;
  width: 100%;
  text-align: left;
  border: none;
}

.buttonActive h3 {
  font-weight: bold;
  font-size: medium;
  color: #2563eb;
}

.visuallyHidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.topbarItens {
  display: flex;
  margin-left: 3rem;
}
