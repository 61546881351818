.content {
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.card {
  border-style: none;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 3rem;
  width: 28rem;
  height: 12rem;
  overflow-y: scroll;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.scheduleCard {
  border-style: none;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 3rem;
  width: 28rem;
  height: 28rem;
  overflow-y: scroll;
}

.scheduleCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.scheduleItem {
  padding: 0.7rem;
}

.infoContainer {
  display: flex;
  align-items: center;
  text-align: right;
}

.cardIcon {
  font-size: 0.9rem;
}

.cardsReview {
  margin-left: 0.5rem;
  font-weight: 500;
}

.cardItem {
  cursor: pointer;
  padding: 0.3rem;
}

.cardItem:hover {
  background-color: #eee;
  border-radius: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #fff;
}

tbody {
  font-size: 0.8rem;
}

td {
  padding: 10px 10px;
}

tr:hover {
  background-color: #eee;
  cursor: pointer;
}

th {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 900;
  padding: 10px 10px;
  text-align: left;
  background-color: #fff;
  cursor: default;
}
