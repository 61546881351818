.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  overflow: auto;
}

.category {
  display: flex;
}

.card {
  background-color: white;
  border-style: none;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 12px;
  padding: 0.5rem;
  margin: 3rem;
  width: 30rem;
  max-height: 28rem;
  min-height: 28rem;
  overflow-y: scroll;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cardItem {
  cursor: pointer;
  background-color: #eee;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItem:hover {
  filter: brightness(90%);
}

.cardItemOut {
  cursor: pointer;
  background-color: #ffcdd2ff;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItemOut:hover {
  filter: brightness(90%);
}

.cardItemConfirmed {
  cursor: pointer;
  background-color: #cce2cb;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItemConfirmed:hover {
  filter: brightness(90%);
}

.cardItemOk {
  cursor: pointer;
  background-color: #BBD6FA;
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.cardItemOk:hover {
  filter: brightness(90%);
}

.img {
  padding-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}

 /* MODAL */

 .modal {
  display: block;
  position: fixed;
  left: 35%;
  top: 8%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 35rem;
  max-width: 35rem;
  max-height: auto;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modal h3 {
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.modal p {
  padding: 2rem;
}

.modalBody {
  padding: 1.5rem;
}

.footer {
  display: flex;
  padding: 0.75rem;
  margin-top: 0.5rem;
  border-top-width: 1px;
  justify-content: space-between;
}

.status {
  display: flex;
  justify-content: space-between;
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.schedule p {
  color: #4b5563;
  font-size: 14px;
  padding: 1rem;
}

.materialIcon {
  color: #9ca3af;
}

.materialIconDelete {
  color: #9ca3af;
  padding-right: 0.3rem;
  cursor: pointer;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.statusButton {
  display: inline-flex;
  cursor: pointer;
  margin-right: auto;
  padding-top: 0.5rem;
}

.statusButton:hover {
  background-color: #eee;
  transition: 0.5s;
}

.statusButton button {
  cursor: pointer;
  background: transparent;
  border: none;
  text-align: left;
  font-size: 14px;
  color: #4b5563;
}

.materialIconStatus {
  color: #9ca3af;
}

.statusModal {
  transition: 0.3s;
  transform: initial;
  display: block;
  position: absolute;
  top: 52%;
  left: 17%;
  width: auto;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 999;
}

.statusBody {
  max-height: 10rem;
  overflow-y: scroll;
}

.statusBody button {
  padding-bottom: 0.7em;
  padding-top: 0.8rem;
  padding-right: 3rem;
  padding-left: 0.8rem;
  color: #6b7280;
  font-size: 13px;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 100%;
  text-align: left;
}

.statusBody button:hover {
  background-color: #eee;
}